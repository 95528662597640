import { getClosest, queryByClass } from './util'

const localPath = window.location.pathname

export default function init () {
  const currentLink = findSelectedTopicLink()
  setupCondensedTopic(currentLink)
  if (!currentLink) return
  setTopicsSelected(currentLink)
}

const findSelectedTopicLink = () => {
  const lvl1Topics = queryByClass('js-topics')
  const allLinks = lvl1Topics.getElementsByTagName('a')
  const currentLink = Array.prototype.find.call(allLinks, x => x.pathname === localPath)
  return currentLink
}

const setupCondensedTopic = currentLink => {
  const trigger = queryByClass('js-topic-toggle')
  const contentSection = queryByClass('js-content')
  const currentLabel = queryByClass('js-current-topic')
  const currentVersion = queryByClass('js-current-version')
  const versionSelector = document.getElementById('versionSelector')

  trigger.addEventListener('click', event => {
    event.preventDefault()
    contentSection.classList.toggle('show-topics')
  })
  currentVersion.addEventListener('click', event => {
    event.preventDefault()
    contentSection.classList.toggle('show-topics')
  })
  currentVersion.textContent = versionSelector.selectedOptions[0].textContent
  if (currentLink) {
    currentLabel.textContent = currentLink.children.length ? currentLink.children[1].textContent : currentLink.textContent
  } else {
    currentLabel.textContent = 'Select topic'
  }
}

const setTopicsSelected = currentLink => {
  const listElem = getClosest('li', currentLink)
  listElem.classList.add('selected')
  climbTheTree(listElem)
}

const climbTheTree = (listElem) => {
  const parent = getClosest('li', listElem.parentElement)
  if (parent) {
    parent.classList.add('expanded')
    climbTheTree(parent)
  }
}

var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function() {
    var content = this.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  });
}