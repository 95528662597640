
export function getClosest (selector, elem) {
  // Get closest match
  for (; elem && elem !== document && elem.nodeType === 1; elem = elem.parentNode) {
    if (elem.tagName.toLowerCase() === selector) {
      return elem
    }
  }
  return null
}

export function queryByClass (className, element) {
  let result = queryAllByClass(className, element)
  return result.length ? result[0] : undefined
}

export function queryAllByClass (className, element) {
  let context = element || document
  return [].slice.call(context.getElementsByClassName(className))
}
