import { queryByClass } from './util'

export default function init () {
  initBurgerMenu()
}

const initBurgerMenu = () => {
  const trigger = queryByClass('js-menu-trigger')
  const menu = queryByClass('js-menu')
  trigger.addEventListener('click', event => {
    menu.classList.toggle('show')
  })
}
